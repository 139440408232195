// Safari, in Private Browsing Mode, looks like it supports sessionStorage but all calls to setItem
// throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
// to avoid the entire page breaking, without having to do a check at each usage of Storage.
if (typeof sessionStorage === 'object') {
    try {
        sessionStorage.setItem('sessionStorage', '1');
        sessionStorage.removeItem('sessionStorage');
    } catch (e) {
        if (e instanceof Error && e.name === 'QuotaExceededError') {
            Storage.prototype._setItem = Storage.prototype.setItem;
            Storage.prototype.setItem = function () {};
        }
    }
}
